.not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh; /* Full viewport height */
    text-align: center;
    color: #dddcea; /* Match the theme */
    background-color: #2c2c2c; /* Match the theme */
  }
  
  .not-found-container h2 {
    margin-bottom: 20px;
    font-size: 2rem;
    color: #ff9c00; /* Accent color */
  }
  
  .not-found-image {
    max-width: 200px;
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional for aesthetics */
  }
  