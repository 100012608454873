::-moz-selection { /* Code for Firefox */
    color: #3c3c3c;
    background: #e08900;
}
    
    ::selection {
    color: #3c3c3c;
    background: #e08900;
}

body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #2c2c2c; /* Dark theme background restored */
    color: #dddcea;
}

p {
    line-height: 2rem;;
}

.spinner {
    padding: 40px 0;
}

.container {
    margin: 0 auto;
    padding: 20px 60px;
    margin-bottom: 20px;
    background-image: url('../assets/bg.png'); 
    background-repeat: repeat; 
    background-size: auto;
    background-position: top left;
    min-height: 100%;
}

.not-found-container {
    background-image: url('../assets/bg.png'); 
    background-repeat: repeat; 
    background-size: auto;
    background-position: top left;
    min-height: 100%;
}

.table-container {
    margin: 5px 0;
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

th, td {
    padding: 12px 15px;
    text-align: center;
    border: 1px solid #444;
}

td:first-child {
    text-align: left;
}

th {
    background-color: #3c3c3c;
    color: #dddcea;
    font-size: 13px;
    text-transform: uppercase;
}

tr:nth-child(even) {
    background-color: #333;
}

tr:hover {
    background-color: #444;
}

button {
    background-color: transparent;
    border: 1px solid #999797;
    color: #999797;
    padding: 12px 20px 8px 20px;
    margin-right: 5px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    opacity: 0.9;
    transition: background-color 0.3s, color 0.3s;
}

button:hover {
    border: 1px solid transparent;
    background-color: #e08900;
    color: #2c2c2c;
}

button.active {
    opacity: 1;
    border: 1px solid #e08900;
    background-color: #ff9c00;
    color: #2c2c2c;
    cursor: default;
}

.page-title {
    font-size: 1.8rem;
    margin-bottom: 30px;
}

.page-content a {
    color: #dddcea !important;
    text-decoration: none;
}

.page-content tr:hover a {
    color: #3c3c3c;
    cursor: pointer;
}

table a:hover {
    text-decoration: underline;
}

.filters-close, .filters-toggle {
    display: none;
}

.about-image {
    width: 100%;
    box-shadow: inset 0px 8px 20px rgba(0, 0, 0, 0.8), /* Top inner shadow */
                inset 0px -8px 20px rgba(0, 0, 0, 0.7), /* Bottom inner shadow */
                inset 8px 0px 20px rgba(0, 0, 0, 0.7), /* Left inner shadow */
                inset -8px 0px 20px rgba(0, 0, 0, 0.7); /* Right inner shadow */
    filter: brightness(0.8); /* Makes the entire image darker */
    border-radius: 10px; /* Optional: adds rounded corners */
    overflow: hidden;
}

@media (max-width: 768px) {

    .container {
        padding: 10px 20px;
    }

    .table-container {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        border: 1px solid #444; /* Optional for aesthetics */
        border-radius: 8px; /* Optional for rounded edges */
    }
    table {
        min-width: 600px; /* Ensure the table content is scrollable */
        margin-top: 0px;
    }
    
    .filters-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(44, 44, 44, 0.95);
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: center; /* Center vertically */
        z-index: 2000;
        padding: 20px;
        gap: 20px;
    }
    
    .filters-overlay.active {
        display: flex;
    }
    
    .filters-close {
        position: absolute;
        top: 20px;
        right: 60px;
        background: none;
        border: none;
        color: #dddcea;
        font-size: 20px;
        cursor: pointer;
        display: block;
    }
    
    .filters {
        display: flex;
        flex-direction: column; /* Vertical layout for filters */
        align-items: center;
        gap: 15px;
        width: 100%; /* Optional for alignment */
    }
    
    .filters button {
        background-color: #444;
        border: 1px solid #666;
        color: #dddcea;
        padding: 10px 15px;
        font-size: 14px;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.3s;
        width: 80%; 
    }
    
    .filters button:hover,
    .filters button.active {
        background-color: #ff9c00;
        color: #2c2c2c;
    }

    .filters-toggle {
        display: block;
        margin-bottom: 20px;
    }

    .filters-overlay.active ~ .nav-toggle {
        display: none;
    } 
    
}
