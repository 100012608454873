/* Navigation Bar Styles */

nav {
    background-color: #2c2c2c; 
    padding: 10px 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

nav .logo {
    margin-top: 5px;
}

nav .logo img {
    width: 220px;
    height: auto;
}

nav ul {
    list-style: none;
    display: flex;
    gap: 15px;
    margin: 0;
    padding: 0;
    align-items: center;
}

nav li {
    display: inline-block;
}

nav a {
    text-decoration: none;
    color: #dddcea;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
    opacity: 0.9;
}

nav a:hover {
    background-color: #ff9c00;
    color: #2c2c2c;
}

nav a.active {
    opacity: 1;
    background-color: #ff9c00;
    color: #2c2c2c;
    cursor: default;
}

.logo a, .logo a.active {
    background-color: transparent;
    padding: 0 !important;
    cursor: pointer;
}

/* Wallet Info Section */

.wallet-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #444;
    border: 1px solid #666;
    border-radius: 5px;
    padding: 0;
    height: 40px;
    gap: 0;
    margin-left: 10px;
}

.wallet-info button:hover {
    padding: 10px 14px !important;
}

a.wallet-address {
    background-color: transparent;
    border: none;
    color: #dddcea;
    font-size: 14px;
    cursor: pointer;
    padding: 10px 15px;
    text-decoration: none;
    border-radius: 5px;
    transition: opacity 0.3s ease-in-out;
}

.wallet-address:hover {
    background-color: transparent;
    color: #dddcea;
}

.disconnect-button {
    background-color: #E00B00;
    color: white;
    border: none;
    font-size: 14px;
    cursor: pointer;
    padding: 10px 15px;
    border-radius: 5px;
    height: 100%;
    transition: background-color 0.3s ease;
    margin-right: 0;
}

.disconnect-button:hover {
    background-color: #FE3328;
    color: #2c2c2c;
}

/* Toggle Button */

.nav-toggle {
    display: none;
    background: none;
    border: none;
    color: #dddcea;
    font-size: 24px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 15px;
    z-index: 1100;
}

/* Mobile Styles */

@media (max-width: 768px) {
    .nav-toggle {
        display: block;
        right: 5px;
        top: 11px;
    }

    nav {
        padding: 10px 20px;
    }

    nav ul {
        display: none;
        flex-direction: column;
        align-items: center;
    }

    nav ul.active {
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #2c2c2c;
        justify-content: center;
        align-items: center;
        gap: 30px;
        z-index: 1000;
        transition: opacity 0.3s ease-in-out;
    }

    nav ul.hidden {
        display: none;
    }

    nav ul.active a {
        padding: 10px 20px;
    }
}
